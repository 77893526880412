body
{
  overflow-x: hidden;
}
.logo
{
  width: 25%;
  margin-top: .5em;
  margin-left: 2em;
}
.logo2
{
  width: 70%;
}
.header
{
  background-image: url("./pexels-pixabay-247765.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.nav-container
{
  position: fixed;
  right: -23rem;
  top: 0px;
  transition: .5s;
  z-index: 100000000000000000000000000;
}
.nav
{
  height: 100vh;
  width: 20rem;
  padding: 2em;
  background: #a9cde8;
}
.close2
{
  font-size: 2em;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  display: none;
}
.nav ul
{
  margin-right: 8em;
}
.nav ul li
{
  list-style-type: none;
  margin-bottom: 2em;
}
.nav ul li a:link
{
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
  color: #1b365e;
}
.nav ul li a:hover
{
  color: #0c1636;
}
.nav ul li a:visited
{
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
  color: #1b365e;
}
.modal-bg
{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
  z-index: 10000;
}
.my-modal
{
  width: 60%;
  margin: 2em auto;
  padding: 2em;
  background: #fff;
}
.my-modal2
{
  width: 25%;
  margin: 1em auto;
  padding: 2em;
  background: #fff;
}
.my-modal h1
{

}
.hero-gloss
{
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100vh;
}
.menu-bars
{
  position: absolute;
  top: 20px;
  right: 20px;
  float: right;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin: 1em;
}
.text-logo
{
  position: absolute;
  left: 27%;
  top: 25%;
  color: #fff;
  font-size: 6em;
}
.dsc
{
  position: absolute;
  left: 5%;
  top: 35%;
  color: #fff;
  width: 90%;
  text-align: justify;
  //font-size: 6em;
}
.dsc h1
{
  text-align: center;
}
.my-display-1
{
  font-size: 1.1em;
}
.myPosition
{
  position: absolute;
  left: 36%;
  top: 35%;
  color: #fff;
  font-size: 6em;
}
.text-logo-top
{
  position: absolute;
  color: #fff;
  margin: .5em;
  font-weight: bold;
}
.spot
{
  color: blue;
}
.sec1
{
  position: fixed;
  top: -200%;
  left: 0px;
  z-index: 100000000000;
  height: 100vh;
  width: 100%;
  background: #efefef;
  text-align: center;
  transition: top .5s;
}
.sec2
{
  text-align: center;
  padding: 4em;
}
.sec2 h1
{
  margin-bottom: 1em;
}
.sec2 ul
{
  list-style-type: none;
  margin-top: 2em;
}
.sec ul li
{
  margin-bottom: 2em;
  font-size: 2em;
}
.nmt
{
  margin-top: -6em;
}
.sec1 p
{
  margin-top: 1em;
  margin-left: 4em;
}
.sec1 ul
{
  margin-top: 2em;
}
.sec1 ul li
{
  list-style-type: none;
  margin-bottom: 1em;
  font-weight: 800;
  font-size: 4em;
  cursor: pointer;
}
.sec1 ul li a:link
{
  color: #1f2550;
  text-decoration: none;
}
.sec1 ul li a:visited
{
  color: #1f2550;
  text-decoration: none;
}
.close-bg
{
  text-align: left !important;
  margin: 1em;
  //background: rgba(0, 0, 0, .5);
  width: 40px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}
.close
{
  text-align: center !important;
  color: #333;
  font-size: 2em;
}
.flyer
{
  max-width: 75%;
}
.contact-content
{
  text-align: left;
}
.contact-content p
{
  margin-bottom: 3em;
}
.map-contact
{
  width:600;
  height:450;
}
@media screen and (max-width:800px)
{
    .header
    {
      height: 60vh;
    }
    .dsc
    {
      position: absolute;
      left: 15%;
      top: 25%;
      color: #fff;
      width: 100%;
      text-align: justify;
      //font-size: 6em;
    }
    .my-display-1
    {
      font-size: .8em;
      display: none;
    }
    .nav
    {
      height: 100vh;
      //width: 10rem;
      padding: 2em;
      background: #a9cde8;
    }
    .my-modal
    {
      width: 90%;
      margin: 2em auto;
      padding: 2em;
      background: #fff;
    }
    .my-modal2
    {
      width: 90%;
      margin: 1em auto;
      padding: 2em;
      background: #fff;
    }
    .hero-gloss
    {
      height: 60vh;
    }
    .text-logo
    {
      left: 18%;
      top: 35%;
      font-size: 4em;
    }
    .menu-bars
    {
      position: absolute;
      top: -10px;
      right: -10px;
    }
    .no-mobile
    {
      display: none;
    }
    .sec1 ul
    {
      margin-top: -4em;
    }
    .sec2 h1
    {
      margin-bottom: 0em;
    }
    .logo2
    {
      margin-top: -1.5em;
      width: 70%;
    }
    .App-logo-footer
    {
      width: 100%;
      margin-bottom: 2em;
    }
    .sec2
    {
      padding: 1em;
    }
    .flyer
    {
      max-width: 90%;
    }
}
.footer
{
  padding: 4em;
  background: #000;
  color: #fff;
}
.footer-top-sec
{
  border-bottom: 1px solid grey;
  padding-bottom: 4em;
}
.footer .icon-top
{
  font-size: 1.5em;
}
.footer-bottom-sec
{
  margin-top: 4em;
}
.footer-about
{
  width: 50%;
}
.footer-contact
{
  margin-bottom: .5em;
  padding: .2em;
}
.footer-contact p
{
  margin-top: .5em;
}
.App-logo-footer
{
  width: 20%;
  margin-bottom: 2em;
}
.info-img
{
  width: 100%;
}
@media screen and (max-width:800px)
{
    .App-logo-footer
    {
      width: 50%;
      margin-bottom: 2em;
    }
    .footer
    {
      padding: 2em;
    }
    .footer-about
    {
      width:100%;
    }
}
.mt-10
{
  margin-top: 10em;
}
.my-card
{
  border: 1px solid #dfdfdf;
  padding: 2em;
  margin: 0px auto;
  border-radius: 5px;
  width: 75%;
}
.my-card h3
{
  padding: 8.2em 0;
}